export const api = $fetch.create({
  async onRequest({ options }) {
    const token = await useAsyncUserToken();
    const headers = (options.headers ||= {});
    if (Array.isArray(headers)) {
      headers.push(['Authorization', `Bearer ${token}`]);
    } else if (headers instanceof Headers) {
      headers.set('Authorization', `Bearer ${token}`);
    } else {
      headers.Authorization = `Bearer ${token}`;
    }
  },
});
